import React, { useEffect } from 'react';
import './index.scss';
import clsx from 'clsx/lite';

type Position =
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'top left'
  | 'top right'
  | 'bottom left'
  | 'bottom right'
  | 'left top'
  | 'left bottom'
  | 'right top'
  | 'right bottom';

interface DropdownProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  triggerButton?: React.ReactNode;
  children?: React.ReactNode;
  position?: Position;
  settingPosition?: boolean;
}

const Dropdown = ({
  isOpen,
  setIsOpen,
  triggerButton,
  children,
  position,
  settingPosition
}: DropdownProps) => {
  useEffect(() => {
    const handleClickOutside = () => setIsOpen(false);

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <button
        className={clsx(
          'dropdown-button',
          settingPosition && 'setting-position'
        )}
        onClick={(e) => {
          if (!isOpen) e.stopPropagation();
          setIsOpen(!isOpen);
        }}
      >
        {triggerButton}
        {isOpen && (
          <div
            className={clsx('dropdown-content', position)}
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </div>
        )}
      </button>
    </>
  );
};

export default Dropdown;
