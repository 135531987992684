import React from 'react';
import { DCA } from '@dusalabs/sdk';
import { faArrowRight, faInfinity } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { routeNames } from 'routes';
import {
  getTokenFromAddress,
  printBigintIsh,
  getIntervalFromSeconds
} from 'utils/methods';

export const DCAsOwn = ({ dcas }: { dcas: DCA[] }) => {
  return (
    <div className='AssetsOwned'>
      <div className='assets-owned-container'>
        {dcas.map((dca, index) => (
          <React.Fragment key={dca.endTime + '' + index}>
            <DCAItem dca={dca} />
            {index < dcas.length - 1 && <div className='line'></div>}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

const DCAItem = ({ dca }: { dca: DCA }) => {
  const tokenIn = getTokenFromAddress(dca.tokenPath[0].address);
  const tokenOut = getTokenFromAddress(
    dca.tokenPath[dca.tokenPath.length - 1].address
  );
  const endTimeInvalid = !dayjs(dca.endTime).isValid();

  return (
    <React.Fragment key={dca.endTime}>
      <Link to={routeNames.dca} className='asset-item'>
        <div className='asset-details'>
          <div>
            <div className='token-logos'>
              <img className='token-logo' src={tokenIn.logoURI} />
              <img className='token-logo' src={tokenOut.logoURI} />
            </div>
            <div className='dca-amount'>
              {printBigintIsh(tokenIn, dca.amountEachDCA)} {tokenIn.symbol}{' '}
              <FontAwesomeIcon icon={faArrowRight} /> {tokenOut.symbol}
            </div>
          </div>
          <div>
            <div>
              <span>Interval: </span>
              <span>{getIntervalFromSeconds(dca.interval / 1000)}</span>
            </div>
            <div>
              <span>Executed: </span>
              <span>
                {1 + Math.floor((Date.now() - dca.startTime) / dca.interval)}/
                {dca.nbOfDCA || '∞'}
              </span>
            </div>
          </div>
          <div>
            <span>End Date: </span>
            <span>
              {dayjs().year() + 15 < dayjs(dca.endTime).year() ||
              endTimeInvalid ? (
                <FontAwesomeIcon icon={faInfinity} />
              ) : (
                <>{dayjs(dca.endTime).format('MMMM D, YYYY, h:mm:ss A')}</>
              )}
            </span>
          </div>
        </div>
      </Link>
    </React.Fragment>
  );
};
