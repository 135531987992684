import { useState } from 'react';
import {
  faTelegram,
  faMedium,
  faGithub,
  faDiscord,
  faXTwitter
} from '@fortawesome/free-brands-svg-icons';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import Dropdown from 'components/Dropdown';
import { NETWORK } from 'utils/config';
import { currentBlock, ONE_PERIOD } from 'utils/constants';
import { baseClient } from 'utils/w3';
import './index.scss';

const Footer = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const nodeStatusQuery = useQuery(
    ['nodeStatus'],
    () =>
      baseClient
        .publicApi()
        .getNodeStatus()
        .catch((err) => {
          console.warn(err);
          toast.error('Could not connect to network');
        }),
    { refetchInterval: ONE_PERIOD * 5 }
  );
  const blockNumber = nodeStatusQuery.data?.next_slot.period || currentBlock;

  return (
    <footer>
      <div className='socials'>
        {[
          { icon: faXTwitter, link: 'https://twitter.com/dusalabs' },
          { icon: faDiscord, link: 'https://dsc.gg/dusalabs' },
          { icon: faMedium, link: 'https://medium.com/@dusalabs' },
          { icon: faTelegram, link: 'https://t.me/dusacommunity' },
          { icon: faGithub, link: 'https://github.com/dusaprotocol' }
          //{ icon: undefined, link: 'https://forms.gle/aWsSwZSm6pPWcmKr5' }
        ].map((social, index) => {
          return (
            <div className='social' key={index}>
              {!social.icon ? (
                <Dropdown
                  isOpen={isDropdownOpen}
                  setIsOpen={setIsDropdownOpen}
                  triggerButton={<span>more</span>}
                  position='top'
                >
                  <div className='footer-more-container'>
                    <div>
                      <a
                        href={social.link}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        List your token
                      </a>
                      <FontAwesomeIcon icon={faExternalLink} />
                    </div>
                  </div>
                </Dropdown>
              ) : (
                <a href={social.link} target='_blank' rel='noopener noreferrer'>
                  <FontAwesomeIcon icon={social.icon} />
                </a>
              )}
            </div>
          );
        })}
      </div>
      {blockNumber && (
        <div className='node-state'>
          <span>{NETWORK}</span>
          <div className='pulse'>
            <div className='pulse__ring'></div>
            <div className='pulse__ring'></div>
          </div>
          <span>{blockNumber}</span>
        </div>
      )}
    </footer>
  );
};

export default Footer;
