import { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

const getWindowSize = () => ({ innerWidth, innerHeight });

const useResizeWindow = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const handleResize = () => setWindowSize(getWindowSize());

  useEffect(() => {
    window.onresize = debounce(handleResize, 200);

    return () => {
      window.onresize = null;
    };
  }, []);

  return windowSize;
};

export default useResizeWindow;
