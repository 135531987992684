import { getMasIfWmas } from 'utils/coreMethods';
import { Pool } from 'utils/pools';
import './index.scss';

const PoolName = ({ token0, token1, binStep }: Pool) => {
  const t0 = getMasIfWmas(token0);
  const t1 = getMasIfWmas(token1);

  return (
    <div className='TokenName'>
      <div className='logo-symbol-container'>
        <div className='token-logo-container'>
          <img className='token-logo' src={t0.logoURI} />
          <img className='token-logo' src={t1.logoURI} />
        </div>
        <span className='symbol'>
          {t0.symbol} - {t1.symbol}
        </span>
      </div>
      <span className='pill'>{binStep / 100} %</span>
    </div>
  );
};

export default PoolName;
