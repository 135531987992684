import {
  IERC20,
  IFactory,
  LimitOrder,
  DCA,
  ILBPair,
  FeeParameters,
  IBaseContract,
  LBPairInformation,
  LBPairReservesAndId
} from '@dusalabs/sdk';
import {
  Args,
  strToBytes,
  byteToU8,
  IDatastoreEntry
} from '@massalabs/massa-web3';
import { IAccount } from '@massalabs/wallet-provider';
import { ArrayTypes, bytesToStr } from '@massalabs/web3-utils';
import {
  CHAIN_ID,
  datastoreApi,
  dcaManagerSC,
  factorySC,
  limitOrderSC,
  vaultManagerSC
} from './config';
import { AutopoolConfig, unknownURI } from './constants';
import { calculateAmount } from './coreMethods';
import {
  cleanAddress,
  createKey,
  emptyBin,
  getBinValue,
  getTokenFromAddress,
  isAddressValid,
  sumBinAmounts,
  u8ArrayToString
} from './methods';
import { Pool } from './pools';
import { MASSA, WMAS } from './tokens';
import { PoolLiquidity, Token, Bin, Deposits, ClaimInfo } from './types';
import { baseClient } from './w3';

// ==================================================== //
// ====               USER BALANCE                 ==== //
// ==================================================== //

export const fetchBalance = (account: string): Promise<bigint> =>
  baseClient
    .wallet()
    .getAccountBalance(account)
    .then((e) => {
      if (!e) return 0n;
      return e.candidate;
    })
    .catch(() => 0n);

export const fetchAccountsBalance = async (
  accounts: IAccount[]
): Promise<number[]> => {
  const nonEmptyAddresses = accounts.map((a) => a.address()).filter(Boolean);
  if (!nonEmptyAddresses.length) return [];
  const balances = await baseClient
    .publicApi()
    .getAddresses(nonEmptyAddresses)
    .then((res) => res.map((r) => Number(r.candidate_balance)));
  // Fill w/ 0 for empty addresses
  return accounts.map(
    (a) => balances[nonEmptyAddresses.indexOf(a.address())] || 0
  );
};

export const fetchTokenBalance = async (
  address: string,
  account: string
): Promise<bigint> => {
  if (address === MASSA.address) return fetchBalance(account);
  else
    return new IERC20(address, baseClient).balanceOf(account).catch(() => 0n);
};

export const fetchAllTokensBalance = async (
  addresses: string[],
  account: string
): Promise<bigint[]> => {
  const masIndex = addresses.indexOf(MASSA.address);
  const massaBalance =
    masIndex === -1 ? 0n : BigInt(await fetchBalance(account));

  const invalidAddresses = addresses.filter(
    (address) => address !== MASSA.address && !isAddressValid(address)
  );
  const invalidAddressesIndexes = invalidAddresses.map((address) =>
    addresses.indexOf(address)
  );

  const data = addresses
    .filter((address) => address !== MASSA.address && isAddressValid(address))
    .map((address) => ({
      address,
      key: strToBytes('BALANCE' + account)
    }));

  try {
    const entries = await baseClient.publicApi().getDatastoreEntries(data);
    const res = entries.map((entry) =>
      entry.candidate_value?.length
        ? new Args(entry.candidate_value).nextU256()
        : 0n
    );

    addresses.includes(MASSA.address) && res.splice(masIndex, 0, massaBalance);
    invalidAddressesIndexes.forEach((i) => res.splice(i, 0, 0n));

    return res;
  } catch (err) {
    console.log('Failed to fetch all tokens balance:', addresses, err);
    return [];
  }
};

// ==================================================== //
// ====                 TOKEN INFO                 ==== //
// ==================================================== //

export const fetchTokenTotalSupply = async (address: string): Promise<bigint> =>
  new IERC20(address, baseClient).totalSupply();

export const fetchTokenInfo = async (address: string): Promise<Token> => {
  return new IBaseContract(address, baseClient)
    .extract(['NAME', 'SYMBOL', 'DECIMALS'])
    .then((res) => {
      if (!res[0]?.length || !res[1]?.length || !res[2]?.length)
        throw new Error('No token info found');

      const token: Token = {
        isNative: false,
        isToken: true,
        chainId: CHAIN_ID,
        equals: WMAS.equals,
        name: u8ArrayToString(res[0]),
        symbol: u8ArrayToString(res[1]),
        decimals: byteToU8(res[2]),
        logoURI: unknownURI,
        address
      };
      return token;
    });
};

export const fetchTokenFromAddress = (address: string): Promise<Token> => {
  try {
    return Promise.resolve(getTokenFromAddress(address));
  } catch {
    return fetchTokenInfo(address);
  }
};

export const getPairAddressTokens = async (pairAddress: string) =>
  new ILBPair(pairAddress, baseClient).getTokens();

export const getTokensFromPairAddress = async (
  pairAddress: string
): Promise<Token[]> => {
  const pairAddressTokens = await getPairAddressTokens(pairAddress);
  return Promise.all(pairAddressTokens.map(fetchTokenFromAddress));
};

export const fetchPairFeeParameters = async (
  pairAddress: string
): Promise<FeeParameters> =>
  new ILBPair(pairAddress, baseClient).feeParameters();

export const getTokensSorted = async (
  _tokenA: string,
  _tokenB: string,
  _binStep: number
): Promise<{ token0: Token; token1: Token }> => {
  const pairAddress = await fetchPairAddress(_tokenA, _tokenB, _binStep);
  const tokens = await getTokensFromPairAddress(pairAddress);
  // Verify that _tokenA and _tokenB are the same as the ones in the pair
  if (
    (_tokenA !== tokens[0].address && _tokenA !== tokens[1].address) ||
    (_tokenB !== tokens[0].address && _tokenB !== tokens[1].address)
  )
    throw new Error('Tokens do not match the pair');

  return { token0: tokens[0], token1: tokens[1] };
};

// ==================================================== //
// ====                  USER DCA                  ==== //
// ==================================================== //

const fetchActiveDCAIds = (account: string): Promise<number[]> =>
  fetchDatastore(dcaManagerSC, 'D::' + account).then((keys) =>
    keys.map((key) => Number(key.split(account)[1]))
  );

export const fetchActiveDCAs = async (account: string): Promise<DCA[]> => {
  const ids = await fetchActiveDCAIds(account);
  const bs = ids.map((id: number) => `D::${account}${id}`);

  return new IBaseContract(dcaManagerSC, baseClient)
    .extract(bs)
    .then((r) => {
      const dcaReturned: DCA[] = [];
      r.forEach((item, i) => {
        if (!item?.length) return;

        const args = new Args(item);
        const amountEachDCA = args.nextU256();
        const interval = Number(args.nextU64());
        const nbOfDCA = Number(args.nextU64());
        const tokenPathStr: string[] = args.nextArray(ArrayTypes.STRING);
        const tokenPath: Token[] = tokenPathStr.map((address) =>
          getTokenFromAddress(address)
        );
        const startTime = Number(args.nextU64());
        const endTime = Number(args.nextU64());

        const dca: DCA = {
          id: ids[i],
          amountEachDCA,
          interval,
          nbOfDCA,
          tokenPath,
          startTime,
          endTime
        };
        dcaReturned.push(dca);
      });

      return dcaReturned;
    })
    .catch(() => []);
};

// ==================================================== //
// ====                 USER ORDERS                ==== //
// ==================================================== //

const fetchEveryOrderId = (address: string): Promise<number[]> =>
  fetchDatastore(limitOrderSC, 'A::' + address).then((keys) =>
    keys.map((key) => Number(key.split(address)[1]))
  );

export const fetchActiveOrders = async (
  account: string
): Promise<LimitOrder[]> => {
  const ids = await fetchEveryOrderId(account);
  const bs = ids.map((id: number) => `A::${account}${id}`);

  return new IBaseContract(limitOrderSC, baseClient)
    .extract(bs)
    .then((r) => {
      const orderReturned: LimitOrder[] = [];
      r.forEach((item, i) => {
        if (!item?.length) return;
        const order = new LimitOrder().deserialize(item).instance;
        order.id = ids[i];

        orderReturned.push(order);
      });

      return orderReturned;
    })
    .catch(() => []);
};

// ==================================================== //
// ====                   FACTORY                  ==== //
// ==================================================== //

export const fetchPoolAddress = (pool: Pool) =>
  fetchPairAddress(pool.token0.address, pool.token1.address, pool.binStep);
export const fetchPairAddress = (
  token0: string,
  token1: string,
  binStep: number
): Promise<string> =>
  getLBPairInformationOpti(
    cleanAddress(token0),
    cleanAddress(token1),
    binStep
  ).then((res) => res.LBPair);

type GetLBPairInformation = typeof IFactory.prototype.getLBPairInformation;
const getLBPairInformationOpti: GetLBPairInformation = async (...params) => {
  const [token0, token1, binStep] = params;
  if (!token0 || !token1)
    throw new Error('Invalid token address', { cause: { token0, token1 } });
  const [tokenA, tokenB] =
    token0 < token1 ? [token0, token1] : [token1, token0];
  const key = 'PAIR_INFORMATION::' + createKey(tokenA, tokenB, binStep);
  const res = await new IFactory(factorySC, baseClient).extract([key]);
  if (!res[0]?.length)
    throw new Error('No pair information found', { cause: key });
  return new LBPairInformation().deserialize(res[0]).instance;
};

export const fetchPairBinSteps = (
  token0: string,
  token1: string
): Promise<number[]> =>
  new IFactory(factorySC, baseClient)
    .getAvailableLBPairBinSteps(cleanAddress(token0), cleanAddress(token1))
    .catch(() => []);

export const fetchPairBinStep = (token0: string, token1: string) =>
  fetchPairBinSteps(token0, token1).then((res) =>
    res.length ? res.sort((a, b) => a - b)[0] : 0
  );

export const fetchQuoteAssets = (): Promise<Token[]> =>
  new IBaseContract(factorySC, baseClient)
    .extract(['QUOTE_ASSETS'])
    .then((res) => {
      if (!res[0]?.length) return [];
      const addresses = bytesToStr(res[0])
        .split(':')
        .filter((a) => a);
      return Promise.all(addresses.map((a) => fetchTokenFromAddress(a)));
    });

// ==================================================== //
// ====              USER LIQUIDITY                ==== //
// ==================================================== //

/**
 * Fetches the liquidity of a given address and pool
 * @param pool
 * @param poolAddress
 * @param account
 * @returns
 */
export const fetchUserLiquidity = async (
  pool: Pool,
  poolAddress: string,
  account: string
) => {
  const liq = await fetchUserLiquidityBins(
    account,
    poolAddress,
    pool.token0,
    pool.token1,
    pool.binStep
  );
  const { amount0, amount1 } = sumBinAmounts(liq);

  const batchSize = 100; // can't fetch all fees at once
  const feesPromises = [];
  for (let i = 0; i < liq.length; i += batchSize) {
    const batch = liq.slice(i, i + batchSize);
    const binIds = batch.map((bin) => bin.id);
    const promise = fetchPendingFees(poolAddress, account, binIds);
    feesPromises.push(promise);
  }
  const feesResults = await Promise.all(feesPromises);
  const fees = feesResults.reduce(
    (acc, curr) => {
      acc.amount0 += curr.amount0;
      acc.amount1 += curr.amount1;
      return acc;
    },
    { amount0: 0n, amount1: 0n }
  );

  const res: PoolLiquidity = {
    ...pool,
    amount0,
    amount1,
    fees0: fees.amount0,
    fees1: fees.amount1
  };

  return { ...res, liq };
};

/**
 * Fetches the bins of a given address and account
 * @param account - The account to fetch the bins from
 * @param address - The address of the pool
 * @param token0 - The first token
 * @param token1 - The second token
 * @param binStep - The bin step
 * @returns - An array of bins
 */
export const fetchUserLiquidityBins = async (
  account: string,
  address: string,
  token0: Token,
  token1: Token,
  binStep: number
): Promise<Bin[]> => {
  const ids = await new ILBPair(address, baseClient).getUserBinIds(account);
  if (!ids.length) return [];

  const entryPromises: Promise<IDatastoreEntry[]>[] = [];
  const batchSize = 1000; // can't fetch all bins at once
  for (let i = 0; i < ids.length; i += batchSize) {
    const batchIds = ids.slice(i, i + batchSize);
    const bs = batchIds.flatMap((id) => [
      { address, key: strToBytes(`bin::${id}`) },
      { address, key: strToBytes(`balances::${id}:${account}`) },
      { address, key: strToBytes(`total_supplies::${id}`) }
    ]);
    if (i + batchSize >= ids.length) {
      bs.push({ address, key: strToBytes('PAIR_INFORMATION') });
    }
    entryPromises.push(baseClient.publicApi().getDatastoreEntries(bs));
  }

  const batchResults = await Promise.all(entryPromises);
  const entries = batchResults.flat();

  const activeIdRes = entries.pop();
  if (!activeIdRes?.candidate_value?.length) {
    console.error('No pair information found');
    return [];
  }
  const activeId = new Args(activeIdRes.candidate_value).nextU32();

  const bins: Bin[] = [];
  for (let i = 0; i < entries.length; i += 3) {
    const [resBin, resBalance, resSupply] = [
      entries[i],
      entries[i + 1],
      entries[i + 2]
    ].map((e) => e.candidate_value);

    if (!resBin || !resBalance || !resSupply) {
      bins.push({
        ...emptyBin,
        id: ids[i / 3]
      });
      continue;
    }

    const returnValueBin = new Args(resBin);
    const totalAmount0 = returnValueBin.nextU256();
    const totalAmount1 = returnValueBin.nextU256();
    const accToken0PerShare = returnValueBin.nextU256();
    const accToken1PerShare = returnValueBin.nextU256();

    const lbTokenAmount = new Args(resBalance).nextU256();
    if (lbTokenAmount === 0n) continue; // push empty bin?

    const totalLBT = new Args(resSupply).nextU256();
    const amount0 = (lbTokenAmount * totalAmount0) / totalLBT;
    const amount1 = (lbTokenAmount * totalAmount1) / totalLBT;
    const value = getBinValue(
      token0,
      token1,
      amount0,
      amount1,
      activeId,
      binStep
    );

    bins.push({
      id: ids[i / 3],
      amount0,
      amount1,
      amountLBT: lbTokenAmount,
      accToken0PerShare,
      accToken1PerShare,
      value
    });
  }

  return bins;
};

// ==================================================== //
// ====                     AL                     ==== //
// ==================================================== //

export const fetchVaultSC = async (
  token0Address: string,
  token1Address: string,
  type: AutopoolConfig
): Promise<string> => {
  const key = 'vaults::' + token0Address + token1Address + type.toString();
  return new IBaseContract(vaultManagerSC, baseClient)
    .extract([key])
    .then((res) => {
      const data = res[0];
      if (!data?.length) throw new Error('Address not found');

      return bytesToStr(data);
    });
};

export const fetchStratSC = async (vaultAddress: string): Promise<string> => {
  return new IBaseContract(vaultAddress, baseClient)
    .extract(['STRATEGY'])
    .then((res) => {
      const data = res[0];
      if (!data?.length) throw new Error('Address not found');

      return bytesToStr(data);
    });
};

// ==================================================== //
// ====                    FEES                    ==== //
// ==================================================== //

export const fetchPendingFees = (
  address: string,
  account: string,
  ids: number[]
): Promise<Deposits> =>
  new ILBPair(address, baseClient).pendingFees(account, ids);

export const fetchPendingFeesIds = async (
  address: string,
  account: string,
  bins: Bin[]
): Promise<ClaimInfo[]> => {
  const promise: Promise<IDatastoreEntry[]>[] = [];
  const batchSize = 1000;
  for (let i = 0; i < bins.length; i += batchSize) {
    const batchIds = bins.slice(i, i + batchSize).map((bin) => bin.id);
    const bs = batchIds.map((id: number) => ({
      address,
      key: strToBytes('accrued_debts::' + account + ':' + id)
    }));
    promise.push(baseClient.publicApi().getDatastoreEntries(bs));
  }

  const batchResults = await Promise.all(promise);
  const entries = batchResults.flat();

  const idsReturned: number[] = [];
  const amounts: { amountX: bigint; amountY: bigint }[] = [];
  entries.forEach((entry, i) => {
    if (!entry.candidate_value?.length) return;

    const args = new Args(entry.candidate_value);
    const debtX = args.nextU256();
    const debtY = args.nextU256();

    const accX = bins[i].accToken0PerShare;
    const accY = bins[i].accToken1PerShare;
    const balance = bins[i].amountLBT;

    const amountX = calculateAmount(accX, balance, debtX);
    const amountY = calculateAmount(accY, balance, debtY);

    if (amountX > 0n || amountY > 0n) {
      idsReturned.push(bins[i].id);
      amounts.push({ amountX, amountY });
    }
  });

  return idsReturned.map((id, i) => ({
    id,
    amounts: amounts[i]
  }));
};

// ==================================================== //
// ====               PAIR INFORMATION             ==== //
// ==================================================== //

// new ILBPair(poolAddress, baseClient).getReservesAndId()
export const fetchPairInformationOpti = (
  poolAddress: string
): Promise<LBPairReservesAndId> =>
  new ILBPair(poolAddress, baseClient)
    .extract(['PAIR_INFORMATION'])
    .then((res) => {
      if (!res[0]?.length) throw new Error('No pair information found');
      const args = new Args(res[0]);
      const activeId = args.nextU32();
      const reserveX = args.nextU256();
      const reserveY = args.nextU256();
      const feesX = { total: args.nextU256(), protocol: args.nextU256() };
      const feesY = { total: args.nextU256(), protocol: args.nextU256() };
      return { activeId, reserveX, reserveY, feesX, feesY };
    });

export const fetchAllBinsId = async (address: string): Promise<number[]> => {
  const bins = await fetch(
    datastoreApi + '/datastore-keys?address=' + address + '&prefix=bin::'
  )
    .then((res) => res.json())
    .then((res) => res.map((key: string) => Number(key.split('bin::')[1])));
  return bins;
};

// ==================================================== //
// ====                     MISC                   ==== //
// ==================================================== //

export const fetchDatastore = (
  address: string,
  filterPrefix?: string
): Promise<string[]> =>
  baseClient
    .publicApi()
    .getAddresses([address])
    .then((res) => {
      const keys = res[0].candidate_datastore_keys.map((key) =>
        String.fromCharCode(...key)
      );
      return keys.filter((key) =>
        filterPrefix ? key.startsWith(filterPrefix) : true
      );
    });
