import { createTRPCProxyClient, httpBatchLink } from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';
import { inferProcedureOutput } from '@trpc/server';
import { api } from './config';
import type { AppRouter } from '../../../backend/api/src/trpc';

// react-query keys
export const queryKeys = {
  pairAddress: 'pairAddress',
  totalSupply: 'totalSupply',
  tokensSorted: 'tokensSorted',
  // pairInformation: 'pairInformation'
  pairInformation: (address: string | undefined) => ['pairInformation', address]
} as const;

export const trpc = createTRPCReact<AppRouter>();
export const trpcClient = createTRPCProxyClient<AppRouter>({
  links: [
    httpBatchLink({
      url: api
    })
  ]
});

export type Price = inferProcedureOutput<AppRouter['getPrice']>[number];
export type DCAExecution = inferProcedureOutput<AppRouter['getDCAs']>[number];
export type OrderExecution = inferProcedureOutput<
  AppRouter['getOrders']
>[number];
export type LeaderboardItem = inferProcedureOutput<
  AppRouter['getLeaderboard']
>[number];
export type GlobalLeaderboardItem = inferProcedureOutput<
  AppRouter['getGlobalLeaderboard']
>[number];
export type TradedBin = inferProcedureOutput<
  AppRouter['getBinsTraded']
>[number];
export type Reward = NonNullable<
  inferProcedureOutput<AppRouter['getRewardPool']>
>;
export type RegisteredDiscord = inferProcedureOutput<
  AppRouter['getRegisteredDiscord']
>;
export type Token = inferProcedureOutput<AppRouter['getToken']>;
export type Swap = inferProcedureOutput<AppRouter['getRecentSwaps']>[number];
export type Liquidity = inferProcedureOutput<
  AppRouter['getRecentLiquidity']
>[number];
