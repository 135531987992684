import { useQueries, useQuery } from '@tanstack/react-query';
import { factorySC } from 'utils/config';
import {
  fetchDatastore,
  getTokensFromPairAddress
} from 'utils/datastoreFetcher';
import { Pool } from 'utils/pools';
import { transformToken } from 'utils/storage';
import { queryKeys } from 'utils/trpc';
import { usePairAddresses } from './useFetchData';

const prefix = 'PAIR_INFORMATION::';
const split = (key: string) => key.substring(prefix.length).split(':');

const useFetchAllPools = () => {
  // KEYS
  const { data: keys } = useQuery(['datastore', factorySC], () =>
    fetchDatastore(factorySC, prefix)
  );

  // POOL ADDRESSES
  const unsortedPools =
    keys?.map((k) => ({
      token0: { address: split(k)[0] },
      token1: { address: split(k)[1] },
      binStep: Number(split(k)[2])
    })) || [];
  const { poolAddresses /* , poolAddressesReady */ } =
    usePairAddresses(unsortedPools);

  // TOKENS SORTED
  const tokensSortedQuery = useQueries({
    queries: poolAddresses.map((pairAddress) => ({
      queryKey: [queryKeys.tokensSorted, pairAddress],
      queryFn: () => getTokensFromPairAddress(pairAddress),
      enabled: !!pairAddress
    }))
  });
  const tokensSortedReady =
    tokensSortedQuery.length > 0 && tokensSortedQuery.every((p) => p.data);
  const pools: Pool[] = tokensSortedReady
    ? tokensSortedQuery.map((p, i) => ({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        token0: transformToken(p.data![0]),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        token1: transformToken(p.data![1]),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        binStep: Number(split(keys![i])[2])
      }))
    : [];

  const query = useQuery(['allPools'], () => Promise.resolve(pools), {
    enabled: tokensSortedReady
  });
  return {
    ...query,
    isLoading: query.data?.length === 0 || query.data === undefined
  };
};

export default useFetchAllPools;
