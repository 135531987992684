import React, { useEffect, useState } from 'react';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { httpBatchLink } from '@trpc/client';
import { Outlet } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import AccountDrawer from 'components/AccountDrawer';
import AccountWrapper from 'components/AccountWrapper';
import Footer from 'components/Footer';
import Headband from 'components/Headband';
import Header from 'components/Header';
import {
  InterfaceLevel,
  SettingsContext,
  SettingsContextType
} from 'context/SettingsContext';
import { FEATURE_FLAGS, actualTheme, api } from 'utils/config';
import { setTheme } from 'utils/darkMode';
import {
  getInterfaceLevel,
  getInterfaceTheme,
  getSwapSlippage,
  getAmountLiquiditySlippage,
  getPriceLiquiditySlippage,
  getAirdropMcap,
  setInterfaceLevel as setInterfaceLevelStorage,
  setSwapSlippage as setSwapSlippageStorage,
  setAmountLiquiditySlippage as setAmountLiquiditySlippageStorage,
  setPriceLiquiditySlippage as setPriceLiquiditySlippageStorage,
  setAirdropMcap as setAirdropMcapStorage
} from 'utils/storage';
import { queryKeys, trpc } from 'utils/trpc';
import ScrollToTop from './ScrollToTop';
import 'react-toastify/dist/ReactToastify.css';
import './assets/sass/index.scss';

const isProduction = process.env.NODE_ENV === 'production';
FEATURE_FLAGS.SENTRY &&
  isProduction &&
  import('@sentry/react').then((Sentry) => {
    Sentry.init({
      dsn: 'https://848a87f408e153cdef30f942969a223b@o4508049985241088.ingest.de.sentry.io/4508202814931024',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration()
      ],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', 'https://app.dusa.io'],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  });

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: any) => {
      console.error(error);
      toast.error(`Something went wrong: ${error.message}`);
    }
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const cacheTime = 24 * 3600000; // milliseconds in day
const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage
});
const persistQueries = [
  queryKeys.pairAddress,
  queryKeys.totalSupply,
  queryKeys.tokensSorted
];
persistQueryClient({
  queryClient,
  persister: localStoragePersister,
  maxAge: cacheTime,
  hydrateOptions: {},
  dehydrateOptions: {
    shouldDehydrateQuery: ({ queryKey }) => {
      return queryKey.some((key) => persistQueries.includes(key as any));
    }
  }
});

const trpcClient = trpc.createClient({
  links: [
    httpBatchLink({
      url: api
    })
  ]
});

const App = () => {
  return (
    <>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <SettingsWrapper>
            <AccountWrapper>
              {FEATURE_FLAGS.PAUSE_SCREEN ? (
                <div className='back-message'>
                  <div>
                    <span>Live at 4pm CEST</span>
                    <div className='emoji'></div>
                  </div>
                </div>
              ) : (
                <>
                  {FEATURE_FLAGS.HEADBAND && <Headband />}
                  <Header />
                  <main>
                    <Outlet />
                  </main>
                  <Footer />
                </>
              )}
              <AccountDrawer />
            </AccountWrapper>
          </SettingsWrapper>
          <ToastContainer pauseOnHover={false} theme='dark' />
          <ScrollToTop />
        </QueryClientProvider>
      </trpc.Provider>
    </>
  );
};

const SettingsWrapper = ({ children }: { children: React.ReactNode }) => {
  const [interfaceLevel, _setInterfaceLevel] =
    useState<InterfaceLevel>(getInterfaceLevel());
  const [interfaceTheme, _setInterfaceTheme] = useState<string>(
    getInterfaceTheme() ?? actualTheme
  );
  const [swapSlippage, _setSwapSlippage] = useState<number>(getSwapSlippage());
  const [amountLiquiditySlippage, _setAmountLiquiditySlippage] =
    useState<number>(getAmountLiquiditySlippage());
  const [priceLiquiditySlippage, _setPriceLiquiditySlippage] = useState<number>(
    getPriceLiquiditySlippage()
  );
  const [airdropMcap, _setAirdropMcap] = useState<number>(getAirdropMcap);
  const [showHeadband, setShowHeadband] = useState(true);

  const setInterfaceLevel = (value: InterfaceLevel) => {
    _setInterfaceLevel(value);
    setInterfaceLevelStorage(value);
  };

  const setInterfaceTheme = (value: string) => {
    _setInterfaceTheme(value);
    setTheme(value);
  };

  const setSwapSlippage = (value: number) => {
    _setSwapSlippage(value);
    setSwapSlippageStorage(value);
  };

  const setAmountLiquiditySlippage = (value: number) => {
    _setAmountLiquiditySlippage(value);
    setAmountLiquiditySlippageStorage(value);
  };

  const setPriceLiquiditySlippage = (value: number) => {
    _setPriceLiquiditySlippage(value);
    setPriceLiquiditySlippageStorage(value);
  };

  const setAirdropMcap = (value: number) => {
    _setAirdropMcap(value);
    setAirdropMcapStorage(value);
  };

  const settings: SettingsContextType = {
    interfaceLevel,
    setInterfaceLevel,
    interfaceTheme,
    setInterfaceTheme,
    swapSlippage,
    setSwapSlippage,
    amountLiquiditySlippage,
    setAmountLiquiditySlippage,
    priceLiquiditySlippage,
    setPriceLiquiditySlippage,
    airdropMcap,
    setAirdropMcap,
    showHeadband,
    setShowHeadband
  };

  useEffect(() => {
    setTheme(interfaceTheme);
  }, []);

  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  );
};

export default App;
