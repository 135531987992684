import { useQueries, useQuery } from '@tanstack/react-query';
import { fetchPairAddress } from 'utils/datastoreFetcher';
import { cleanAddress } from 'utils/methods';
import { Pool } from 'utils/pools';
import { queryKeys } from 'utils/trpc';

const queryKey = queryKeys.pairAddress;

export const usePairAddress = (pool: Partial<Pool>) => {
  const { token0, token1, binStep } = pool;
  const query = useQuery(
    [
      queryKey,
      cleanAddress(token0?.address),
      cleanAddress(token1?.address),
      binStep
    ],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => fetchPairAddress(token0!.address, token1!.address, binStep!),
    { enabled: !!token0?.address && !!token1?.address && !!binStep }
  );
  return query;
};

type SimpleToken = { address: string };
type SimplePool = { token0: SimpleToken; token1: SimpleToken; binStep: number };
export const usePairAddresses = (pools: (Pool | SimplePool)[]) => {
  const poolAddressesQuery = useQueries({
    queries: pools.map((pool) => ({
      queryKey: [
        queryKey,
        pool.token0.address,
        pool.token1.address,
        pool.binStep
      ],
      // prettier-ignore
      queryFn: () => fetchPairAddress(pool.token0.address, pool.token1.address, pool.binStep),
      enabled: !!pool.token0.address && !!pool.token1.address && !!pool.binStep
    }))
  });
  const poolAddresses = poolAddressesQuery.map((p) => p.data || '');
  const poolAddressesReady = poolAddresses.every((p) => p);
  return { ...poolAddressesQuery, poolAddresses, poolAddressesReady };
};
