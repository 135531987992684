import { useContext, useRef, useState } from 'react';
import { faHeart as faHeartRegular } from '@fortawesome/free-regular-svg-icons';
import { faHeart, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx/lite';
import { useLocation } from 'react-router-dom';
import Button from 'components/Button';
import Modal, { ModalProps } from 'components/Modal';
import { AccountWrapperContext } from 'context/AccountWrapperContext';
import { fetchAllTokensBalance, fetchTokenInfo } from 'utils/datastoreFetcher';
import {
  formatNumberWithSubscriptZeros,
  isAddressValid,
  printBigintIsh,
  sortTokens
} from 'utils/methods';
import { type Pool, pools } from 'utils/pools';
import {
  getFavTokens,
  getImportedTokens,
  updateFavTokens,
  updateImportedTokens
} from 'utils/storage';
import { DUSA_AIRDROP, DUSA_GOV, MASSA, WMAS, tokens } from 'utils/tokens';
import { Token } from 'utils/types';
import './index.scss';

interface TokenModalProps extends ModalProps {
  activeToken: Token | undefined;
  changeActiveToken: (t: Token) => void;
  otherTokenSelected?: Token | undefined;
  isFirstInput?: boolean | undefined;
  quoteAssets?: Token[];
  removedTokens?: Token[];
}

const tabs = ['Swap', 'Limit', 'DCA'] as const;
type Tab = (typeof tabs)[number];

const TokenModal = ({
  activeToken,
  changeActiveToken,
  showModal,
  setShowModal,
  otherTokenSelected,
  isFirstInput,
  quoteAssets,
  removedTokens = undefined
}: TokenModalProps) => {
  const [results, setResults] = useState<Token[]>();
  const [importedTokens, setImportedTokens] =
    useState<Token[]>(getImportedTokens());
  const defaultBalances: bigint[] = new Array(
    tokens.length + importedTokens.length
  ).fill(0n);

  const allTokens =
    quoteAssets ||
    sortTokens(
      tokens
        .filter(
          (token) =>
            !(removedTokens || [])
              .concat([DUSA_AIRDROP, DUSA_GOV])
              .includes(token)
        )
        .concat(importedTokens)
        .filter(
          (token, index, self) =>
            index === self.findIndex((t) => t.address === token.address) ||
            !tokens.some(
              (originalToken) => originalToken.address === token.address
            )
        )
    );

  const { connectedAddress } = useContext(AccountWrapperContext);
  const addresses = allTokens.map((t) => t.address);
  const balancesQuery = useQuery({
    queryKey: ['balances', connectedAddress, addresses.join()],
    queryFn: () => fetchAllTokensBalance(addresses, connectedAddress),
    enabled: !!connectedAddress
  });
  const balances = balancesQuery.data || defaultBalances;

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const activeTab: Tab = (searchParams.get('tab') || 'Swap') as Tab;
  const isLimit = activeTab === 'Limit';
  const inputRef = useRef(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const [favTokens, setFavTokens] = useState<Token[]>(
    allTokens.filter((t) => getFavTokens().some((f) => f.equals(t)))
  );

  const saveFavTokens = (fav: Token[]) => {
    setFavTokens(fav);
    updateFavTokens(fav);
  };

  const performSearch = async (_search: string) => {
    if (_search !== '') {
      if (isAddressValid(_search)) {
        fetchTokenInfo(_search).then((r) => r && setResults([r]));
      } else {
        setResults(
          tokens.filter(
            (t) =>
              t.symbol.toLowerCase().includes(_search.toLowerCase()) ||
              t.name.toLowerCase().includes(_search.toLowerCase())
          )
        );
      }
    } else setResults(undefined);
  };

  const filterPool = (pool: Pool) =>
    otherTokenSelected?.equals(pool.token0) ||
    otherTokenSelected?.equals(pool.token1);

  // wtf is this
  const allowedTokens = isFirstInput
    ? otherTokenSelected?.equals(WMAS)
      ? allTokens.filter((t) => !t.equals(MASSA))
      : otherTokenSelected?.equals(MASSA)
        ? allTokens.filter((t) => !t.equals(WMAS))
        : allTokens
    : pools
        .filter(filterPool)
        .flatMap((pool) =>
          [pool.token0, pool.token1].filter((t) =>
            otherTokenSelected?.equals(t)
          )
        );

  const renderTokenList = () => {
    return (
      <div className='token-list'>
        {allTokens.map((token, index) => {
          const included = allowedTokens.includes(token);
          return (
            <div
              className={clsx(
                'token',
                (activeToken?.equals(token) || (isLimit && !included)) &&
                  'active',
                !tokens.includes(token) && 'imported-token'
              )}
              key={index}
              onClick={() => {
                if (activeToken?.equals(token) || (isLimit && !included))
                  return;

                changeActiveToken(token);
                setShowModal(false);
                document.body.style.overflow = 'auto';
              }}
            >
              <div>
                {favTokens && (
                  <div className='heart-svg'>
                    {favTokens.some((t) => t.equals(token)) ? (
                      <FontAwesomeIcon
                        icon={faHeart}
                        onClick={(e) => {
                          e.stopPropagation();
                          const fav = favTokens.filter((t) => !t.equals(token));
                          saveFavTokens(fav);
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faHeartRegular}
                        onClick={(e) => {
                          e.stopPropagation();
                          const fav = favTokens.concat(token);
                          saveFavTokens(fav);
                        }}
                      />
                    )}
                  </div>
                )}

                <img src={token.logoURI} alt='logo' />
                <div className='token-info'>
                  <span>{token.symbol}</span>
                  <span className='nowrap'>
                    {!tokens.includes(token) && 'Added by user • '}
                    {token.name}
                  </span>
                </div>
              </div>
              <div className='balance'>
                {connectedAddress && (
                  <>
                    <span>Balance:</span>{' '}
                    <span>
                      {formatNumberWithSubscriptZeros(
                        printBigintIsh(
                          token,
                          balances[index] !== undefined ? balances[index] : 0n
                        )
                      )}
                    </span>
                  </>
                )}
              </div>
              {!tokens.includes(token) && (
                <FontAwesomeIcon
                  icon={faXmark}
                  onClick={(e) => {
                    e.stopPropagation();
                    const newImportedTokens = importedTokens.filter(
                      (t) => !t.equals(token)
                    );
                    setImportedTokens(newImportedTokens);
                    updateImportedTokens(newImportedTokens);
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderResults = () => {
    return (
      <div className='token-list'>
        {results &&
          results.map((result, index) => {
            return (
              <div className='token result-token' key={index}>
                <img src={result.logoURI} alt='logo' />
                <div className='result-token-info'>
                  <span>{result.symbol}</span>
                  <span className='nowrap'>{result.name}</span>
                </div>
                <Button
                  text='Import'
                  variant='contained'
                  disabled={allTokens.some((importedToken) =>
                    importedToken.equals(result)
                  )}
                  onClick={() => {
                    importedTokens.push(result);
                    updateImportedTokens(importedTokens);
                    setResults(undefined);
                  }}
                />
              </div>
            );
          })}
        {results && !results.length && <div>No results found.</div>}
      </div>
    );
  };

  return (
    <Modal
      title='Select a token'
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <div className='TokenModal-content'>
        {!quoteAssets && (
          <div
            className='search-input-container'
            onKeyDown={() => {
              inputRef.current &&
                (inputRef.current as HTMLInputElement).focus();
            }}
          >
            <FontAwesomeIcon
              icon={faSearch}
              className='search-icon'
              onClick={() =>
                inputRef.current &&
                (inputRef.current as HTMLInputElement).focus()
              }
            />
            <input
              ref={inputRef}
              type='text'
              className='search-input'
              placeholder='Search name or paste address'
              onChange={(e) => {
                setSearchValue(e.target.value);
                performSearch(e.target.value);
              }}
              value={searchValue}
            />
            {searchValue ? (
              <FontAwesomeIcon
                icon={faXmark}
                className='clear-icon'
                onClick={() => {
                  setSearchValue('');
                  setResults(undefined);
                }}
              />
            ) : null}
          </div>
        )}
        {favTokens?.length && !results ? (
          <div className='fav-tokens-list'>
            {favTokens.map((token, index) => {
              const same = activeToken ? token.equals(activeToken) : false;
              const _token = allowedTokens.find((t) => t.equals(token));

              return (
                <div
                  className={clsx(
                    'fav-token',
                    same && 'active-token',
                    isLimit &&
                      allowedTokens.some((t) => t.equals(token)) &&
                      'disabled-token'
                  )}
                  key={index + token.symbol}
                  onClick={() => {
                    if (same || !_token) return;

                    changeActiveToken(_token);
                    setShowModal(false);
                  }}
                >
                  <div className='fav-token-info'>
                    <span>{token.symbol}</span>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
        {!results ? renderTokenList() : null}
        {results ? renderResults() : null}
      </div>
    </Modal>
  );
};

export default TokenModal;
