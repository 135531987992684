import {
  ChainId,
  // DCA_MANAGER_ADDRESS,
  LB_FACTORY_ADDRESS,
  LB_ROUTER_ADDRESS,
  LIMIT_ORDER_MANAGER_ADDRESS
  // VAULT_MANAGER_ADDRESS
} from '@dusalabs/sdk';
import { BUILDNET_CHAIN_ID, MAINNET_CHAIN_ID } from '@massalabs/web3-utils';

const getEnvVar = (key: string, defaultValue?: string) => {
  const val = import.meta.env[key];
  if (val === undefined && !defaultValue)
    throw new Error(`Missing env variable: ${key}`);
  return val ?? defaultValue; // will return val if its an empty string (testing purposes)
};

// export const api = `${window.location.protocol}//${window.location.hostname}:3001/trpc`;
export const api = getEnvVar('VITE_API');
export const baseApi = api.replace('/trpc', '');
export const datastoreApi = getEnvVar('VITE_DATASTORE_API');

export const NETWORK = getEnvVar('VITE_NETWORK_NAME');
export const CHAIN_ID = getEnvVar('VITE_CHAIN_ID') as any as ChainId;
export const CHAIN_URL = getEnvVar('VITE_CHAIN_URL');
export const IS_BUILDNET = Number(CHAIN_ID) === ChainId.BUILDNET;
export const MASSA_CHAIN_ID = IS_BUILDNET
  ? BUILDNET_CHAIN_ID
  : MAINNET_CHAIN_ID;

export const FEATURE_FLAGS = {
  DCA: getEnvVar('VITE_ENABLE_DCA') == 'true',
  FAUCET: getEnvVar('VITE_ENABLE_FAUCET') == 'true',
  LIMIT: getEnvVar('VITE_ENABLE_LIMIT') == 'true',
  AUTONOMOUS_LIQUIDITY: getEnvVar('VITE_ENABLE_AUTONOMOUS') == 'true',
  PAUSE_SCREEN: getEnvVar('VITE_ENABLE_PAUSE_SCREEN') == 'true',
  GOVERNANCE: getEnvVar('VITE_ENABLE_PUBLIC_GOVERNANCE') == 'true',
  STREAK: getEnvVar('VITE_ENABLE_STREAK') == 'true',
  HEADBAND: getEnvVar('VITE_ENABLE_HEADBAND') == 'true',
  CREATE_POOL: getEnvVar('VITE_ENABLE_CREATE_POOL') == 'true',
  REWARDS: getEnvVar('VITE_ENABLE_REWARDS') == 'true',
  POINTS: getEnvVar('VITE_ENABLE_POINTS') == 'true',
  POINTS_V2: getEnvVar('VITE_ENABLE_POINTS_V2') == 'true',
  CEDESTORE: getEnvVar('VITE_ENABLE_CEDESTORE') == 'true',
  DEGEN_MODE: getEnvVar('VITE_ENABLE_DEGEN_MODE') == 'true',
  TXS_FROM_DB: getEnvVar('VITE_ENABLE_TXS_FROM_DB') == 'true',
  ALL_POINTS_DETAILS: getEnvVar('VITE_ENABLE_ALL_POINTS_DETAILS') == 'true',
  COPY_DISTRIBUTION: getEnvVar('VITE_ENABLE_COPY_DISTRIBUTION') == 'true',
  SENTRY: getEnvVar('VITE_ENABLE_SENTRY') == 'true'
};

export const factorySC = LB_FACTORY_ADDRESS[CHAIN_ID];
export const routerSC = LB_ROUTER_ADDRESS[CHAIN_ID];
export const vaultManagerSC =
  'AS1tMptxuET37W2uNdi8msiMbR6HW1A9VvUffUG1E8UCT38yzAYp'; // VAULT_MANAGER_ADDRESS[CHAIN_ID];
export const dcaManagerSC =
  'AS1Bqe3kVoTyxr6rLKLMbFr2jyVtHdagNd7W9zpXEjLvnb2GmXMJ'; // DCA_MANAGER_ADDRESS[CHAIN_ID];
export const limitOrderSC = LIMIT_ORDER_MANAGER_ADDRESS[CHAIN_ID];

export const faucetSC = 'AS1JZHjFZ8fQ8xsoS94rAdxqHU8FuGTBkmPRXdvDm9dtb6jGhkhb';
export const governanceSC =
  'AS1AsM9PAr5qTQ5gK6WjrXvRKPspocMFxcRxfFWpDWXBL5KMPpz4';
export const rewarderSC =
  'AS1mviXQDY1QW2poumXD89EkXasyeGx4qSCXdcGH5p4pXmur5zEv';
export const scriptSC = 'AS1oMcGodnCPxp9Ck4yTv1MHkHS9xWzrGmqMcbKu9Vc6qWs2Pigd';

export const EXPLORER =
  NETWORK === 'mainnet'
    ? 'https://explorer.massa.net/mainnet'
    : 'https://massexplo.io';
export const MAS_SUPPLY_ENDPOINT =
  'https://stats.massa.foundation/grs/api.dws?q=totalcoins';

export const DISCORD_CLIENT_ID = getEnvVar('VITE_DISCORD_CLIENT_ID');
export const DISCORD_CLIENT_SECRET = getEnvVar('VITE_DISCORD_CLIENT_SECRET');

export const storageThemeVersion = '0';
export const actualTheme = 'Dusa';
