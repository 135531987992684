import { faCheck, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClipboard from 'hooks/useClipboard';

const CopyIcon = ({ text }: { text: string }) => {
  const { copied, copy } = useClipboard(text);
  return <FontAwesomeIcon icon={copied ? faCheck : faCopy} onClick={copy} />;
};

export default CopyIcon;
