import { lazy } from 'react';
import withPageTitle from './components/PageTitle';

const DCA = lazy(() => import('./pages/DCA'));
const ErrorPage = lazy(() => import('./pages/ErrorPage'));
const Pool = lazy(() => import('./pages/Pool'));
const Pools = lazy(() => import('./pages/Pools'));
const Create = lazy(() => import('./pages/Pools/Create'));
const Portfolio = lazy(() => import('./pages/Portfolio'));
const Trade = lazy(() => import('./pages/Trade'));
const Faucet = lazy(() => import('./pages/Faucet'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Governance = lazy(() => import('./pages/Governance'));
const CreateProposal = lazy(() => import('./pages/Governance/CreateProposal'));
const Vote = lazy(() => import('./pages/Governance/Vote'));
const Points = lazy(() => import('./pages/Points'));
const CexOnRamp = lazy(() => import('./pages/CexOnRamp'));
const Wrapped = lazy(() => import('./pages/Wrapped'));

export const routeNames = {
  home: '/',
  trade: '/trade',
  liquidity: '/pools',
  create: '/pools/create',
  liquidityItem: '/pools/:token0/:token1/:binStep',
  earn: '/earn',
  dca: '/dca',
  faucet: '/faucet',
  dashboard: '/dashboard',
  portfolio: '/portfolio',
  portfolioUser: '/portfolio/:userAddress',
  governance: '/governance',
  createProposal: '/governance/create-proposal',
  vote: '/governance/vote/:id',
  points: '/points',
  cexOnRamp: '/cexOnRamp',
  wrapped: '/wrapped',
  error: '*'
} as const;

interface Route {
  path: (typeof routeNames)[keyof typeof routeNames];
  title: string;
  component: React.FC;
}

const routes: Array<Route> = [
  {
    path: routeNames.home,
    title: 'Home',
    component: Trade
  },
  {
    path: routeNames.trade,
    title: 'Trade',
    component: Trade
  },
  {
    path: routeNames.liquidity,
    title: 'Pools',
    component: Pools
  },
  {
    path: routeNames.create,
    title: 'Create Pool',
    component: Create
  },
  {
    path: routeNames.liquidityItem,
    title: 'Pool',
    component: Pool
  },
  {
    path: routeNames.faucet,
    title: 'Faucet',
    component: Faucet
  },
  {
    path: routeNames.dca,
    title: 'DCA',
    component: DCA
  },
  {
    path: routeNames.portfolio,
    title: 'Portfolio',
    component: Portfolio
  },
  {
    path: routeNames.portfolioUser,
    title: 'Portfolio',
    component: Portfolio
  },
  {
    path: routeNames.dashboard,
    title: 'Dashboard',
    component: Dashboard
  },
  {
    path: routeNames.governance,
    title: 'Governance',
    component: Governance
  },
  {
    path: routeNames.createProposal,
    title: 'Governance - Create',
    component: CreateProposal
  },
  {
    path: routeNames.vote,
    title: 'Governance - Vote',
    component: Vote
  },
  {
    path: routeNames.points,
    title: 'Points',
    component: Points
  },
  {
    path: routeNames.cexOnRamp,
    title: 'CEX On-Ramp',
    component: CexOnRamp
  },
  {
    path: routeNames.wrapped,
    title: '2024 Wrapped',
    component: Wrapped
  },
  {
    path: routeNames.error,
    title: 'Error',
    component: ErrorPage
  }
];

export const mappedRoutes = routes.map((route) => {
  const title = route.title ? `${route.title} • Dusa` : 'Dusa Protocol';

  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent
  };
});
