import React, { useContext, useState } from 'react';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'components/Dropdown';
import SwitchButton from 'components/SwitchButton';
import './index.scss';
import { SettingsContext } from 'context/SettingsContext';

const GlobalSettings = () => {
  const [showDropDown, setShowDropDown] = useState(false);
  const { interfaceTheme, setInterfaceTheme } = useContext(SettingsContext);

  const toggleTheme = (style: string) => {
    if (interfaceTheme === style) {
      if (style === 'Dusa') {
        setInterfaceTheme('Halloween');
      } else setInterfaceTheme('Dusa');
      return;
    }
    setInterfaceTheme(style);
  };

  return (
    <Dropdown
      isOpen={showDropDown}
      setIsOpen={setShowDropDown}
      triggerButton={
        <div className='trigger'>
          <FontAwesomeIcon icon={faGear} />
        </div>
      }
      position='bottom left'
    >
      <div className='settings-container'>
        <h3>Interface Settings</h3>
        <div className='settings-content'>
          {['Dusa', 'Halloween'].map((style) => (
            <div key={style} className='settings-content-item'>
              <span>{style} Interface</span>
              <SwitchButton
                isActive={interfaceTheme === style}
                onClick={() => toggleTheme(style)}
              />
            </div>
          ))}
        </div>
      </div>
    </Dropdown>
  );
};

export default GlobalSettings;
