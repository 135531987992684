import { useContext } from 'react';
import Button, { ButtonProps } from 'components/Button';
import { AccountWrapperContext } from 'context/AccountWrapperContext';

const ConnectButton = (props: Partial<ButtonProps>) => {
  const { setShowDrawer } = useContext(AccountWrapperContext);

  return (
    <>
      <Button
        {...props}
        onClick={() => {
          setShowDrawer((prev) => !prev);
        }}
        variant='contained'
        text='Connect Wallet'
      />
    </>
  );
};

export default ConnectButton;
