import { TokenAmount } from '@dusalabs/sdk';
import { useQueries, useQuery } from '@tanstack/react-query';
import { FEATURE_FLAGS } from 'utils/config';
import {
  fetchActiveDCAs,
  fetchActiveOrders,
  fetchAllTokensBalance,
  fetchUserLiquidity
} from 'utils/datastoreFetcher';
import { roundTokenAmount, sortTokens, toFraction } from 'utils/methods';
import { getImportedTokens } from 'utils/storage';
import { tokens as _tokens } from 'utils/tokens';
import { PoolLiquidity, TokenInfo } from 'utils/types';
import { fetchALBatch } from './useFetchAL';
import useFetchAllPools from './useFetchAllPools';
import { usePairAddresses } from './useFetchData';
import { useTokenValues } from './useFetchData/tokenValue';

const filterPool = (p: Pick<PoolLiquidity, 'amount0' | 'amount1'>) =>
  p.amount0 > 0 || p.amount1 > 0;

const useFetchAccount = (userAddress: string) => {
  // console.log('useFetchAccount', userAddress);
  const connected = !!userAddress;
  const opts = { enabled: connected };

  const tokens = sortTokens(_tokens.concat(getImportedTokens()))
    .filter((t) => !!t.address)
    .filter(
      (token, index, self) =>
        index === self.findIndex((t) => t.address === token.address) ||
        !_tokens.some(
          (originalToken) => originalToken.address === token.address
        )
    );
  const tokenValues = useTokenValues(tokens);

  const allPoolsQuery = useFetchAllPools();
  const allPools = allPoolsQuery.data || [];
  const { poolAddresses, poolAddressesReady } = usePairAddresses(allPools);

  const tokenAddresses = tokens.map((t) => t.address);
  const balancesQuery = useQuery(
    ['balances', userAddress, tokenAddresses.join()],
    () => fetchAllTokensBalance(tokenAddresses, userAddress),
    opts
  );
  // TODO: refactor to use useQueries? duplicate fetch in usePairManager
  const balances = balancesQuery.data || [];

  const poolsOwnQuery = useQueries({
    queries: poolAddresses.map((poolAddress, i) => ({
      queryKey: ['liquidity', poolAddress, userAddress],
      queryFn: () => fetchUserLiquidity(allPools[i], poolAddress, userAddress),
      enabled: connected && poolAddressesReady && allPoolsQuery.isFetched
    }))
  });
  const poolsOwnReady = poolsOwnQuery.every((p) => p.data);
  const poolsOwn = poolsOwnReady
    ? poolsOwnQuery
        .map((p) => p.data as PoolLiquidity)
        .filter((p) => p.amount0 > 0 || p.amount1 > 0)
    : [];
  const refetchPoolsOwn = () => poolsOwnQuery.forEach((p) => p.refetch());

  const autoPoolsOwnQuery = useQuery(
    ['autoPoolsOwn', userAddress],
    () => fetchALBatch(userAddress).then((r) => r.filter(filterPool)),
    { enabled: connected && FEATURE_FLAGS.AUTONOMOUS_LIQUIDITY }
  );
  const autoPoolsOwn = autoPoolsOwnQuery.data || [];

  const activeOrdersQuery = useQuery(
    ['activeOrders', userAddress],
    () => fetchActiveOrders(userAddress),
    { enabled: connected && FEATURE_FLAGS.LIMIT }
  );
  const activeOrders = activeOrdersQuery.data || [];

  const activeDCAsQuery = useQuery(
    ['activeDCAs', userAddress],
    () => fetchActiveDCAs(userAddress),
    { enabled: connected && FEATURE_FLAGS.DCA }
  );
  const activeDCAs = activeDCAsQuery.data || [];

  const calcPoolValue = (pool: PoolLiquidity) => {
    const { token0, token1 } = pool;
    const token0Index = tokens.findIndex((t) => t.equals(token0));
    const token1Index = tokens.findIndex((t) => t.equals(token1));
    const token0Value = tokenValues && tokenValues[token0Index];
    const token1Value = tokenValues && tokenValues[token1Index];
    if (!token0Value || !token1Value) return 0;

    const token0Amount = new TokenAmount(token0, pool.amount0 + pool.fees0);
    const token1Amount = new TokenAmount(token1, pool.amount1 + pool.fees1);
    const value = Number(
      token0Amount
        .multiply(toFraction(token0Value))
        .add(token1Amount.multiply(toFraction(token1Value)))
        .toSignificant(6)
    );
    return value;
  };

  const poolsValue =
    poolsOwn?.reduce((acc, curr) => acc + calcPoolValue(curr), 0) ?? 0;
  const autopoolsValue =
    autoPoolsOwn?.reduce((acc, curr) => acc + calcPoolValue(curr), 0) ?? 0;
  const tokensValue =
    tokens?.reduce((acc, _curr, i) => {
      if (!balances.length) return 0;
      return acc + roundTokenAmount(_curr, balances[i]) * tokenValues[i];
    }, 0) ?? 0;
  const portfolioValue = tokensValue + poolsValue + autopoolsValue;

  const tokensInfo: TokenInfo[] | undefined =
    balances.length && tokenValues.length
      ? tokens
          .map((token, i) => ({
            balance: balances[i],
            price: tokenValues[i],
            token
          }))
          .sort((a, b) => {
            const aVal = roundTokenAmount(a.token, a.balance) * a.price;
            const bVal = roundTokenAmount(b.token, b.balance) * b.price;
            return bVal - aVal;
          })
      : undefined;

  const refetch = () => {
    balancesQuery.isFetched && balancesQuery.refetch();
    poolsOwnReady && refetchPoolsOwn();
    autoPoolsOwnQuery.isFetched && autoPoolsOwnQuery.refetch();
    activeDCAsQuery.isFetched && activeDCAsQuery.refetch();
    activeOrdersQuery.isFetched && activeOrdersQuery.refetch();
  };
  const isLoading =
    balancesQuery.isLoading ||
    !poolsOwnReady ||
    (FEATURE_FLAGS.AUTONOMOUS_LIQUIDITY && autoPoolsOwnQuery.isLoading) ||
    (FEATURE_FLAGS.DCA && activeDCAsQuery.isLoading) ||
    (FEATURE_FLAGS.LIMIT && activeOrdersQuery.isLoading);

  return {
    tokensInfo,
    activeDCAs,
    activeOrders,
    poolsOwn,
    autoPoolsOwn,
    poolsValue,
    autopoolsValue,
    tokensValue,
    portfolioValue,
    isLoading,
    trigger: refetch
  };
};

export default useFetchAccount;
