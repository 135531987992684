import { faWaterLadder } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'components/Spinner';
import { PoolsOwn } from 'pages/Portfolio/components/Pools';
import { FEATURE_FLAGS } from 'utils/config';
import { PoolLiquidity } from 'utils/types';
import './index.scss';

interface MyPoolsProps {
  poolsOwn: PoolLiquidity[] | undefined;
  autoPoolsOwn: PoolLiquidity[] | undefined;
  isLoading: boolean;
  isSelf?: boolean;
}

const MyPools = ({
  poolsOwn,
  autoPoolsOwn,
  isLoading,
  isSelf = true
}: MyPoolsProps) => {
  if (!poolsOwn && !autoPoolsOwn) return <Spinner />;

  return (
    <div className='pf-section-tokens'>
      {FEATURE_FLAGS.AUTONOMOUS_LIQUIDITY && autoPoolsOwn?.length ? (
        <PoolsOwn poolsOwn={autoPoolsOwn} isAutonomous />
      ) : null}
      {poolsOwn?.length ? (
        <PoolsOwn poolsOwn={poolsOwn} isAutonomous={false} />
      ) : null}
      {isLoading ? (
        <Spinner />
      ) : !poolsOwn?.length && !autoPoolsOwn?.length ? (
        <div className='no-liquidity'>
          <FontAwesomeIcon icon={faWaterLadder} />
          {isSelf ? (
            <span>You don&apos;t have any liquidity.</span>
          ) : (
            <span>No liquidity</span>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default MyPools;
