import React, { ReactNode, useEffect, useRef } from 'react';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx/lite';
import './index.scss';

export interface ModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  children?: ReactNode;
  variant?: 'default' | 'large';
}

const Modal = ({
  showModal,
  setShowModal,
  title,
  children,
  variant = 'default'
}: ModalProps) => {
  const background = useRef<HTMLDivElement>(null);

  const closeModal = () => {
    setShowModal(false);
    document.body.style.overflow = 'auto';
  };

  const onKeydown = (e: KeyboardEvent) => {
    e.key === 'Escape' && closeModal();
  };

  useEffect(() => {
    if (showModal) document.body.style.overflow = 'hidden';
  }, [showModal]);

  useEffect(() => {
    document.addEventListener('keydown', onKeydown);

    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  }, []);

  return (
    <div className='Modal'>
      <div
        className='Modal-background'
        ref={background}
        onMouseDown={(e) => background.current === e.target && closeModal()}
      >
        <div className={clsx('Modal-container', variant)}>
          <div className={clsx('top', title && 'top-title')}>
            <span className='title'>{title}</span>
            <button className='close' onClick={closeModal}>
              <FontAwesomeIcon icon={faX} />
            </button>
          </div>

          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
