import { createContext } from 'react';
import { DCA, LimitOrder } from '@dusalabs/sdk';
import { ProviderService } from 'hooks/useWalletProvider';
import { PoolLiquidity, TokenInfo } from 'utils/types';

export interface AccountWrapperContextType extends ProviderService {
  showDrawer: boolean;
  setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  portfolioValue: number;
  tokensValue: number;
  poolsValue: number;
  autopoolsValue: number;
  tokensInfo: TokenInfo[] | undefined;
  poolsOwn: PoolLiquidity[] | undefined;
  autoPoolsOwn: PoolLiquidity[] | undefined;
  activeDCAs: DCA[] | undefined;
  activeOrders: LimitOrder[] | undefined;
  isLoading: boolean;
  refetchDrawer: () => void;
  closeDrawer: () => void;
}

export const AccountWrapperContext = createContext<AccountWrapperContextType>(
  {} as AccountWrapperContextType
);
