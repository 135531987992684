import { Client, ProviderType } from '@massalabs/massa-web3';
import { CHAIN_URL } from './config';

export const providers = [
  { url: CHAIN_URL, type: ProviderType.PUBLIC },
  { url: CHAIN_URL, type: ProviderType.PRIVATE }
];

export const baseClient: Client = new Client({
  providers,
  retryStrategyOn: false
});
