import React, { useContext } from 'react';
import './index.scss';
import { faExternalLink, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SettingsContext } from 'context/SettingsContext';

const Headband = () => {
  const { showHeadband, setShowHeadband } = useContext(SettingsContext);
  if (!showHeadband) return null;
  return (
    <div className='Headband'>
      <div></div>
      <div className='headband-content'>
        <span className='headband-content-text'>
          <span>Incentives program is still ongoing 🔥</span>
        </span>
        <a
          className='headband-content-link'
          href='https://docs.dusa.io/concepts/rewards'
          target='_blank'
          rel='noopener noreferrer'
        >
          Discover more
          <FontAwesomeIcon icon={faExternalLink} />
        </a>
      </div>
      <div className='hide-headband-container'>
        <FontAwesomeIcon icon={faX} onClick={() => setShowHeadband(false)} />
      </div>
    </div>
  );
};

export default Headband;
