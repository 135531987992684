import { buildExplorerLink } from 'utils/methods';
import './index.scss';

interface TxToastProps {
  title: string;
  txId: string;
}

const TxToast = ({ title, txId }: TxToastProps) => {
  const explorerUrl = buildExplorerLink('operation', txId);
  return (
    <div className='TxToast'>
      <span>{title}</span>
      <br />
      <a href={explorerUrl} target='_blank' rel='noreferrer'>
        View on Explorer
      </a>
    </div>
  );
};

export default TxToast;
